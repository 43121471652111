.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above other content */
    animation: fadeIn 0.5s ease-in-out;
}

.alert-box {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content vertically */
    padding: 20px;
    background-color: #2a2a2a; /* Dark background */
    color: #fff; /* White text */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    text-align: center;
    width: 14%; /* Ensure it doesn't get too wide */
    transition: fade-out 0.5s ease; /* Fade out effect */
}

.checkmark {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    margin-bottom: 12px; /* Space between the checkmark and the text */
}

.checkmark-circle {
    stroke-width: 2;
    stroke: #4caf50; /* Green circle stroke */
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-linecap: round;
    animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
    stroke-width: 2;
    stroke: #4caf50; /* Green check stroke */
    stroke-linecap: round;
}

.fade-out {
    animation: fadeOut 0.5s ease-in-out forwards; /* Use forwards to keep the final state after animation ends */
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
