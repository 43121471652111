@keyframes gradientAnimation {
    0% {
        background-position: 10% 50%;
    }
    50% {
        background-position: 90% 50%;
    }
    100% {
        background-position: 10% 50%;
    }
}

.navbar {
    width: 15%;
    min-width: 60px;
    height: 100vh; /* Full height of the viewport */
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.5s ease-in-out;
    background: linear-gradient(
        -85deg,
        rgb(21, 0, 80),
        rgba(55, 13, 118, 0.5),
        rgba(6, 54, 166, 0.5),
        rgba(84, 21, 104, 0.5)
    );
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px; /* Space for logo */
    box-sizing: border-box;
    color: white;
    font-weight: bold;
    font-size: 1.3em;
}
.navbar.closed {
    width: 7%;
}
.logo-section {
    position: absolute; /* Position logo-section absolutely within navbar */
    top: 25; /* Align to the top of the navbar */
    left: 0; /* Align to the left of the navbar */
    width: 100%; /* Stretch across the entire width of the navbar */
    height: 150px; /* Fixed height for the logo section */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 0.5s ease-in-out, width 0.5s ease-in-out;
}

.logo-section img {
    width: 100%; /* Adjust the size of the logo */
    transition: width 0.5s ease-in-out;
}

.navbar.closed .logo-section {
    height: 60px; /* Reduced height when navbar is closed */
    transition: height 0.5s ease-in-out;
}

.menu-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
    transition: width 0.5s ease-in-out;
}


.menu-item {
    height: 60px;
    padding: 15px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center; /* Center items for both open and closed states */
    overflow: hidden; /* Hide the overflowing content during transition */
    transition: background-color 0.3s ease, transform 0.5s ease;
    cursor: pointer;
    position: relative; /* To position the icon absolutely within */
}
.navbar.open .menu-item {
    width: 100%;
    transform: translateX(0);
}

.menu-item span {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-decoration: none;
    margin: 10px 0 10px 12px;
    color: var(--primary-color);
    display: inline-block; /* Use inline-block for transition to work */
    transition: opacity 0.3s ease; /* Smooth transition for transform and opacity */
    opacity: 0; /* Start fully transparent */
}

.navbar.open .menu-item span {
    opacity: 1; /* Fade in to fully visible */
    transition: opacity 0.3s ease; /* Smooth transition for transform and opacity */
    transition-delay: 200ms;
}

.navbar.open .menu-item:hover span {
    color: var(--secondary-color);
    transition: color 0.5s ease; /* Smooth transition for transform and opacity */
}

.menu-item:hover {
    background-color: #00000055;
}

.icon {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    display: inline-block; /* Use inline-block for transition to work */
    color: white;
    transition: opacity 0.3s ease; /* Smooth transition for transform and opacity */
    opacity: 0; /* Start fully transparent */
}

.navbar.open .icon {
    transition: opacity 0.3s ease; /* Smooth transition for transform and opacity */
    opacity: 1; /* Fade in to fully visible */
    transition-delay: 50ms;
}

.toggle-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: transparent; /* Slightly lighter grey */
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.4); /* Slightly lighter grey */
    border-radius: 12px;
    height: 64px;
    width: 64px;
    transition: all 0.5s;
    font-size: 24px;
}

.toggle-button:hover {
    transition: 0.5s;
    background-color: rgba(0, 0, 0, 0.9);;
}