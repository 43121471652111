.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100; /* High z-index to ensure it covers other content */
}

.modal-content {
    background-color: #2a2a2a; /* Dark background */
    color: #fff; /* White text */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    text-align: center;
    max-width: 400px; /* Maximum width */
}

.modal-close-btn {
    background-color: transparent;
    color: #bbb; /* Grey text */
    border: 2px solid #bbb; /* Grey border */
    padding: 8px 16px;
    font-size: 1rem;
    margin-top: 20px; /* Space from the content */
    cursor: pointer;
    border-radius: 5px; /* Rounded edges */
    transition: all 0.3s ease;

    /* Darken text and border on hover */
    &:hover {
        color: #fff;
        border-color: #fff;
    }
}
