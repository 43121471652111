textarea {
    width: 100%;
    background-color: #333;
    border: 2px solid var(--secondary-color);
    color: var(--text-color);
    padding: 15px;
    border-radius: 8px;
    resize: vertical;
    outline: none;
    transition: border-color 0.6s ease;
    width: 55vw;
    height: 40vh;
    font-family: var(--font-family);
    font-size: large;
}

textarea:focus {
    border-color: #ffd208;
}

.notebox-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.notebox-actions {
    display: flex;
    gap: 10px; /* Space between buttons */
    margin-top: 10px;
}

.notebox-btn {
    background-color: transparent; /* Transparent background */
    color: #aaaaaa; /* Grey text color */
    font-size: 1rem; /* Adjust font size as needed */
    font-weight: bold; /* Bold text for better readability */
    cursor: pointer; /* Cursor changes to a pointer to indicate it's clickable */
    transition: color 0.3s ease, border-color 0.3s ease; /* Smooth transition for hover effects */
    border:none;
    outline: none;
    /* Spacing for layout - optional */
    display: inline-block;
    font-family: var(--fa-font-solid);
}

.notebox-btn:hover{
    color: #ffffff; /* Lighter text color on hover/focus for better visibility */
    outline: none; /* Removes the outline to keep the style clean (ensure accessibility with other means) */
}
