@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
    --background-color: #121212;
    --primary-color: #8854d0; /* Purple */
    --secondary-color: #26a69a; /* Teal */
    --primary-accent-color: #8854d0;
    --secondary-accent-color: #fc85ae;
    --text-color: #d3d3d3;
    --hover-color: #9b72aa;
    --font-family: "Poppins", sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    background-color: var(--background-color);
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.container {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally in the container */
    gap: 20px; /* Adds space between child elements */
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (orientation: portrait) {
    .title {
        animation: fadeInDown 1s ease forwards;
        font-size: 7vw;
        color: var(--primary-color);
        text-align: center;
        margin: 0 0 50px;
    }
}

@media only screen and (orientation: landscape) {
    .title {
        animation: fadeInDown 1s ease forwards;
        font-size: 4vw;
        color: var(--primary-color);
        text-align: center;
        margin: 0 0 50px;
    }
}
