.timer {
    font-size: 1rem; /* Adjust as needed */
    color: #fff; /* Light text for the dark theme */
    background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent background */
    padding: 0.5rem 1rem; /* Padding inside the timer */
    border-radius: 5px; /* Rounded corners */
    display: inline-block; /* Only as wide as necessary */
    margin: 10px; /* Space around the timer */
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Stylish font */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}
