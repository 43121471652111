.button {
    padding: 10px 20px;
    background-color: transparent; /* Minimalistic look */
    color: var(--text-color);
    border: 2px solid var(--primary-accent-color); /* Subtle border */
    border-radius: 5px;
    cursor: pointer;
    font-family: var(--font-family);
    font-size: 1rem;
    text-transform: uppercase;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

.button:hover, .button:focus {
    background-color: var(--primary-color); /* Fill color on hover */
    color: var(--background-color); /* Text color contrast */
    transform: translateY(-3px); /* Slight lift effect */
}

.main-container{
    color: var(--text-color);
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}